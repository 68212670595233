var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            visible: _vm.banner,
            width: "900px",
            title: _vm.title,
          },
          on: {
            "update:visible": function ($event) {
              _vm.banner = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品" } },
                    [
                      _c("el-autocomplete", {
                        attrs: {
                          "fetch-suggestions": _vm.handlerGoodsList,
                          "value-key": "goods_name",
                          placeholder: "请输入商品内容",
                        },
                        on: { select: _vm.handleSelect },
                        model: {
                          value: _vm.keyword,
                          callback: function ($$v) {
                            _vm.keyword = $$v
                          },
                          expression: "keyword",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "上传图片" } },
                    [
                      _c(
                        "el-upload",
                        {
                          attrs: {
                            action:
                              _vm.baseURL + "/baseAdmin/common/upload-image",
                            "list-type": "picture-card",
                            name: "files",
                            accept: ".png, .jpg, .jpeg, .gif, .bmp, .jfif",
                            headers: _vm.headers,
                            "before-upload": _vm.loadBefore,
                            "on-success": _vm.onloadSucc,
                            "on-error": _vm.onloadError,
                            "on-change": _vm.onChange,
                            "file-list": _vm.fileList,
                          },
                        },
                        [_c("i", { staticClass: "el-icon-plus" })]
                      ),
                      _c(
                        "el-dialog",
                        {
                          attrs: {
                            modal: false,
                            "close-on-click-modal": false,
                            visible: _vm.dialogVisible,
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.dialogVisible = $event
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              width: "100%",
                              src: _vm.dialogImageUrl,
                              alt: "",
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
                    _vm._v(
                      " 建议图片尺寸：690*300像素，您可使用美图秀秀等图片编辑软件修改尺寸 "
                    ),
                  ]),
                  _c("el-form-item", { attrs: { label: "状态效果" } }, [
                    _c("img", {
                      staticStyle: { width: "180px", height: "120px" },
                      attrs: { src: _vm.dialogImageUrl },
                    }),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v("保 存")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.banner = !_vm.banner
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }