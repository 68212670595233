<!--
 * @Author: 候怀烨
 * @Date: 2021-01-20 15:12:53
 * @LastEditTime: 2021-02-22 16:55:36
 * @LastEditors: Please set LastEditors
 * @Description: 添加修改banner图列表
 * @FilePath: \sd-vue-admin\src\views\project\marketing\management\panel\components\AddBanner.vue
-->
<template>
  <div>
    <el-dialog
      :modal="false"
      :visible.sync="banner"
      width="900px"
      :title="title"
    >
      <div>
        <el-form>
          <el-form-item label="商品">
            <el-autocomplete
              v-model="keyword"
              :fetch-suggestions="handlerGoodsList"
              value-key="goods_name"
              placeholder="请输入商品内容"
              @select="handleSelect"
            ></el-autocomplete>
            <!-- <add-banner
              :f-key="form.goods_name"
              @select-goods-all="selectGoods($event)"
            ></add-banner> -->
          </el-form-item>
          <el-form-item label="上传图片">
            <el-upload
              :action="baseURL + '/baseAdmin/common/upload-image'"
              list-type="picture-card"
              name="files"
              accept=".png, .jpg, .jpeg, .gif, .bmp, .jfif"
              :headers="headers"
              :before-upload="loadBefore"
              :on-success="onloadSucc"
              :on-error="onloadError"
              :on-change="onChange"
              :file-list="fileList"
            >
              <i class="el-icon-plus"></i>
              <!-- <div slot="file" slot-scope="{ file }">
                <div v-if="file.is_default == '1'" class="picD">默认</div>
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  alt=""
                />
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span
                    class="el-upload-list__item-preview"
                    @click="setDefault(file)"
                  >
                    <i class="el-icon-picture-outline"></i>
                  </span>
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div> -->
            </el-upload>

            <el-dialog
              :modal="false"
              :close-on-click-modal="false"
              :visible.sync="dialogVisible"
            >
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
          </el-form-item>
          <div style="margin-bottom: 20px">
            建议图片尺寸：690*300像素，您可使用美图秀秀等图片编辑软件修改尺寸
          </div>
          <el-form-item label="状态效果">
            <img style="width: 180px; height: 120px" :src="dialogImageUrl" />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handlersave">保 存</el-button>

        <el-button @click="banner = !banner">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  // import AddBanner from '@/components/goodslist/index.vue'
  import { getInputList } from '@/api/goodsSearchInput'
  import { postAction } from '@/api/Employee'
  import { baseURL } from '@/config'
  export default {
    components: {
      // AddBanner,
    },
    data() {
      return {
        saveshow: 1,
        banner: false,
        title: '新建轮播广告图管理',
        timeout: null,
        form: {
          goods_id: '',
          goods_name: '',
          img_url: '',
          specs: '',
        },
        keyword: '',
        url: {
          goodsList: '/baseAdmin/goods/index',
          bianji: '/shopAdmin/config/banner-save',
        },

        mineData: null,
        editor: null,
        editorData: '',
        disabled: false,
        dialogImageUrl: '',
        dialogVisible: false,
        headers: { token: '' },
        fileList: [],
        content: '',
      }
    },
    computed: {
      baseURL() {
        return baseURL
      },
    },
    watch: {
      banner(val) {
        if (val == false) {
          this.title = '新建轮播广告图管理'
          this.keyword = ''
          this.form.goods_id = ''
          this.form.goods_name = ''
          this.form.img_url = ''
          this.form.specs = ''
          this.fileList = []
        }
      },
      showdialog(val) {
        if (val) {
          console.log('token', this.$token)
        }
      },
      fileList: {
        handler(val, oldval) {
          console.log(val, '这个是变化吗')
          this.form.img_url = val[val.length - 1].url
        },
      },
      'form.img_url'(val) {
        this.dialogImageUrl = val
      },
    },

    created() {
      this.headers.token = this.$store.getters['user/accessToken']
      this.mineData = this.alldata
      console.log(this.mineData)
      // this.fileList = this.mineData.info.goods_imgs
      console.log(this.fileList)
    },
    methods: {
      selectGoods(row) {
        console.log(row, '我是啥')
        try {
          this.form.goods_id = row.goods_id
        } catch (error) {}
      },
      //编辑保存
      handlersave() {
        if (this.form.goods_id == '') {
          this.$message({
            message: '请选择商品',
            type: 'warning',
          })
        } else {
          if (this.form.img_url == '') {
            this.$message({
              message: '请上传广告图',
              type: 'warning',
            })
          } else {
            postAction(this.url.bianji, this.form)
              .then((res) => {
                console.log(res)
                this.$message({
                  message: res.msg,
                  type: 'success',
                })
                this.banner = false
                this.$emit('getlist')
              })
              .catch((err) => {
                console.log(err)
              })
          }
        }
      },
      // 设置默认图片
      setDefault(file) {
        this.fileList.forEach((item, index) => {
          this.fileList[index].is_default = '0'
          if (item.uid == file.uid) {
            this.fileList[index].is_default = '1'
          }
        })
        console.log(this.fileList)
      },
      handleRemove(file) {
        console.log(file)
        console.log(this.fileList)
        this.fileList.forEach((item, index) => {
          if (item.uid == file.uid) {
            this.fileList.splice(index, 1)

            this.dialogImageUrl = fileList[fileList.length - 1].url
            this.form.img_url = fileList[fileList.length - 1].url
          } else {
            this.form.img_url = ''
          }
        })
      },
      handlePictureCardPreview(file) {
        console.log('放大')
        console.log(file)
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      },
      // 上传状态改变
      onChange(file, fileList) {
        // console.log('上传状态改变 的钩子')
        // console.log(file)
        // console.log(fileList)
      },
      // 上传前
      loadBefore(file) {
        console.log('上传前 的钩子')
        console.log(file)
        if (file.size > 5242880) {
          this.$message.error('本地上传图片大小不能超过5MB')
          return false
        }
        if (
          file.type == 'image/png' ||
          file.type == 'image/jpeg' ||
          file.type == 'image/gif' ||
          file.type == 'image/bmp'
        ) {
        } else {
          this.$message.error('上传图片支持gif、jpg、png、jpeg、bmp、jfif')
          return false
        }
      },
      // 上传时 的钩子
      onloadEvent(res, file, fileList) {
        console.log('上传时 的钩子')
        console.log(res, '1号')
        console.log(file, '2号')
        console.log(fileList, '3号')
      },
      // 上传成功
      onloadSucc(res, file, fileList) {
        console.log('上传成功时 的钩子')
        console.log(res, '1号')
        console.log(file, '2号')
        console.log(fileList, '3号')
        if (res.code == 200) {
          this.fileList = fileList
          this.dialogImageUrl = fileList[fileList.length - 1].url
          this.form.img_url = fileList[fileList.length - 1].url
          this.fileList[this.fileList.length - 1].url = res.data[0].url
          // this.fileList[this.fileList.length - 1].info = res.data[0].name
          this.fileList[this.fileList.length - 1].type = file.raw.type
          this.fileList[this.fileList.length - 1].is_default = '0'
          console.log(this.fileList)
        }
      },
      // 上传失败
      onloadError(err, file, fileList) {
        console.log('上传失败 的钩子')
        console.log(err)
        console.log(file)
        console.log(fileList)
        this.$message.error('上传图片失败，请重试')
        return false
      },
      handlerdata(val) {
        this.title = '编辑'
        console.log(val)
        this.form = JSON.parse(JSON.stringify(val))
        this.keyword = val.goods_name
        this.$set(this.form, 'img_url', val.img_url)
        Object.assign(this.fileList, this.form.img_url)
      },
      handleSelect(val) {
        console.log(val)
        this.form.goods_id = val.id
        this.form.goods_name = val.goods_name
      },
      handlerGoodsList(queryString, cb) {
        console.log(queryString)
        postAction(this.url.goodsList, {
          keyword: queryString,
          pageSize: -1,
          sale_type: '1,3',
        })
          .then((res) => {
            console.log(res)
            clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
              cb(res.data)
            }, 3000 * Math.random())
          })
          .catch((err) => {
            console.log(err)
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .wrapper {
    // min-height: 510px;
  }
  .upPic {
    margin: 15px 0;
  }
  .el-upload-list__item {
    margin: 0 20px 8px 0;
  }
  .upPic {
    margin: 0;
  }
  .el-alert {
    margin: 0;
  }
  .el-dialog__wrapper {
    // z-index: 2002 !important;
  }
  .picD {
    position: absolute;
    top: 0;
    right: 0;
    float: right;
    width: 35px;
    color: #fff;
    text-align: center;
    content: '';
    background: rgb(84, 85, 84, 0.5);
    border-radius: 20px;
  }
</style>
