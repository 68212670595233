<!--
 * @Author: your name
 * @Date: 2021-01-21 14:16:04
 * @LastEditTime: 2021-01-21 16:00:05
 * @LastEditors: Please set LastEditors
 * @Description: 关联商品
 * @FilePath: \sd-vue-admin\src\views\project\marketing\management\panel\components\AddGoods.vue
-->
<template>
  <div>
    <el-dialog
      :modal="false"
      :visible.sync="goodsnum"
      title="关联商品"
      :close-on-click-modal="false"
    >
      <div>
        <el-row>
          <el-table stripe :data="tableData">
            <el-table-column type="index" align="center"></el-table-column>
            <el-table-column
              v-for="(list, index) in colemd"
              :key="index"
              :label="list.label"
              :align="list.align"
              :prop="list.prop"
              width=""
              clearable
            >
              <template v-if="list.label == '排序'" #default="{ row }">
                <el-input v-model="row.sort" />
              </template>
              <template
                v-else-if="list.label == '商品名称'"
                #default="{ row, $index }"
              >
                <goods-search
                  ref="goodsSearch"
                  :f-key="row.goods_name"
                  :onlyshow-kucun="false"
                  @add-rows="addRows($event, $index)"
                  @select-goods-all="goodsSelect($event, row, $index)"
                ></goods-search>
                <!-- <el-autocomplete
                  v-model="row.goods_name"
                  :fetch-suggestions="handlerGoodsList"
                  value-key="goods_name"
                  placeholder="请输入商品名称、简拼"
                  @select="handleSelect"
                ></el-autocomplete> -->
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  v-show="scope.$index != tableData.length - 1"
                  type="text"
                  @click="handlerAdd(scope.$index, scope.row)"
                >
                  添加
                </el-button>
                <el-button
                  v-show="scope.$index != tableData.length - 1"
                  type="text"
                  @click="handlerdelete(scope.$index, scope.row)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handlerSave">保 存</el-button>

        <el-button @click="goodsnum = !goodsnum">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  import goodsSearch from '@/baseComponents/goodsSearch'

  export default {
    components: { goodsSearch },
    data() {
      return {
        goodsnum: false,
        url: {
          list: '/shopAdmin/config/cate-goods-list',
          goodsList: '/baseAdmin/goods/index',
          save: '/shopAdmin/config/cate-goods-save',
        },
        tableData: [],
        cate_id: '',
        colemd: [
          {
            label: '排序',
            align: 'center',
            prop: 'sort',
            width: '',
          },
          {
            label: '商品名称',
            align: 'center',
            prop: 'goods_name',
            width: '',
          },
          {
            label: '规格',
            align: 'center',
            prop: 'goods_specs',
            width: '',
          },
        ],
      }
    },
    methods: {
      addRows(val, index) {
        console.log('addRows', val)
        let goods_id = []
        val.forEach((i) => {
          // if (i.goods_id) goods_id.push(i.goods_id)
          this.tableData.splice(
            index + 1,
            0,
            JSON.parse(JSON.stringify({ ...i, sort: 0 }))
          )
        })
        // 新加商品
      },
      goodsSelect(val, row, index) {
        console.log('goodsSelect', val)
        row.goods_name = val.goods_name
        if (val.goods_id > 0) {
          Object.assign(row, val)
          row.goods_specs = val.specs
          // this.addRow()
          this.tableData.push({
            goods_id: '',
            goods_name: '',
            goods_specs: '',
            id: '',
            sort: 0,
          })
        }
      },
      handlerSave() {
        var a = this.tableData.filter((item) => {
          return item.goods_id != ''
        })
        if (a.length > 0) {
          a.forEach((item) => {
            delete item.goods_name
            delete item.goods_specs
            delete item.id
          })
          console.log(a, '过滤后的')
          postAction(this.url.save, {
            cate_id: this.cate_id,
            goods_data: JSON.stringify(a),
          })
            .then((res) => {
              console.log(res)
              this.$message({
                type: 'success',
                success: res.msg,
              })
              this.$emit('getlist')
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          console.log(a, '过滤后的')
          postAction(this.url.save, {
            cate_id: this.cate_id,
            goods_data: JSON.stringify(a),
          })
            .then((res) => {
              console.log(res)
              this.$message({
                type: 'success',
                success: res.msg,
              })
              this.$emit('getlist')
            })
            .catch((err) => {
              console.log(err)
            })
        }
      },
      handlerAdd(index, row) {
        this.tableData.splice(index, 0, JSON.parse(JSON.stringify(row)))
      },
      handlerdelete(index, row) {
        this.tableData.splice(index, 1)
      },
      handlerData(row) {
        this.cate_id = row.id
        postAction(this.url.list, { cate_id: row.id })
          .then((res) => {
            console.log(res)
            this.tableData = res.data
            var a = {
              goods_id: '',
              goods_name: '',
              goods_specs: '',
              id: '',
              sort: 0,
              entd: false,
            }
            this.tableData.push(a)
            // Object.assign(this.tableData, res.data)
            console.log(this.tableData)
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handleSelect(val) {
        console.log(val)
        let a = {
          goods_id: val.id,
          goods_name: val.goods_name,
          goods_specs: val.specs,
          id: '',
          sort: this.tableData[this.tableData.length - 1].sort,
        }
        this.tableData.splice(
          this.tableData.length - 1,
          0,
          JSON.parse(JSON.stringify(a))
        )
        this.tableData[this.tableData.length - 1].sort = 0
        this.tableData[this.tableData.length - 1].goods_name = ''
        console.log(this.tableData, '加完的数据')
      },
      handlerGoodsList(queryString, callback) {
        console.log(queryString)
        postAction(this.url.goodsList, {
          keyword: queryString,
          pageSize: -1,
          sale_type: '1,3',
        })
          .then((res) => {
            console.log(res)
            callback(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      },
    },
  }
</script>

<style></style>
