var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            visible: _vm.goodsnum,
            title: "关联商品",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.goodsnum = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-table",
                    { attrs: { stripe: "", data: _vm.tableData } },
                    [
                      _c("el-table-column", {
                        attrs: { type: "index", align: "center" },
                      }),
                      _vm._l(_vm.colemd, function (list, index) {
                        return _c("el-table-column", {
                          key: index,
                          attrs: {
                            label: list.label,
                            align: list.align,
                            prop: list.prop,
                            width: "",
                            clearable: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              list.label == "排序"
                                ? {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      return [
                                        _c("el-input", {
                                          model: {
                                            value: row.sort,
                                            callback: function ($$v) {
                                              _vm.$set(row, "sort", $$v)
                                            },
                                            expression: "row.sort",
                                          },
                                        }),
                                      ]
                                    },
                                  }
                                : list.label == "商品名称"
                                ? {
                                    key: "default",
                                    fn: function (ref) {
                                      var row = ref.row
                                      var $index = ref.$index
                                      return [
                                        _c("goods-search", {
                                          ref: "goodsSearch",
                                          refInFor: true,
                                          attrs: {
                                            "f-key": row.goods_name,
                                            "onlyshow-kucun": false,
                                          },
                                          on: {
                                            "add-rows": function ($event) {
                                              return _vm.addRows($event, $index)
                                            },
                                            "select-goods-all": function (
                                              $event
                                            ) {
                                              return _vm.goodsSelect(
                                                $event,
                                                row,
                                                $index
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    },
                                  }
                                : null,
                            ],
                            null,
                            true
                          ),
                        })
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          scope.$index !=
                                          _vm.tableData.length - 1,
                                        expression:
                                          "scope.$index != tableData.length - 1",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlerAdd(
                                          scope.$index,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" 添加 ")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          scope.$index !=
                                          _vm.tableData.length - 1,
                                        expression:
                                          "scope.$index != tableData.length - 1",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handlerdelete(
                                          scope.$index,
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(" 删除 ")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlerSave } },
                [_vm._v("保 存")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.goodsnum = !_vm.goodsnum
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }