var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 6 } }, [
            _c("span", { staticStyle: { "font-size": "30px" } }, [
              _vm._v("轮播广告图管理"),
            ]),
          ]),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlerAdd } },
                [_vm._v("新 增")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-table",
        { attrs: { stripe: "", data: _vm.tableData } },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "排序",
              width: "80",
              type: "index",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "图片", width: "250" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-image", {
                      staticStyle: { width: "180px", height: "120px" },
                      attrs: {
                        src: row.img_url,
                        "preview-src-list": _vm.srcList,
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "链接商品",
              width: "120",
              prop: "goods_name",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.goods_name) + _vm._s(scope.row.specs)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", width: "150", prop: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleroperating(row)
                          },
                        },
                      },
                      [_vm._v("修改")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("add-banner", { ref: "banner", on: { getlist: _vm.getlist } }),
      _c(
        "el-row",
        { staticStyle: { padding: "20px 0px" }, attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 6 } }, [
            _c("span", { staticStyle: { "font-size": "30px" } }, [
              _vm._v("推荐商品板块设置"),
            ]),
          ]),
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-table",
        { attrs: { stripe: "", data: _vm.classdata } },
        [
          _c("el-table-column", {
            attrs: { label: "序号", align: "center", type: "index" },
          }),
          _c("el-table-column", {
            attrs: { label: "板块", align: "center", prop: "name" },
          }),
          _c("el-table-column", {
            attrs: { label: "关联商品", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "text-decoration": "underline",
                          cursor: "pointer",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handlernum(scope.row)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(scope.row.goods_num) + " ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("add-goods", { ref: "goods", on: { getlist: _vm.getlist } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }