<!-- NAME[epic=网销] 版面设置 -->
<template>
  <div style="padding: 20px">
    <el-row :gutter="20">
      <el-col :span="6">
        <span style="font-size: 30px">轮播广告图管理</span>
      </el-col>
      <el-col :span="6">
        <el-button type="primary" @click="handlerAdd">新 增</el-button>
        <!-- <el-button v-has="'add'" type="primary" @click="handlerAdd">
          新 增
        </el-button> -->
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <el-table stripe :data="tableData">
      <el-table-column
        align="center"
        label="排序"
        width="80"
        type="index"
      ></el-table-column>
      <el-table-column align="center" label="图片" width="250">
        <template #default="{ row }">
          <el-image
            style="width: 180px; height: 120px"
            :src="row.img_url"
            :preview-src-list="srcList"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="链接商品"
        width="120"
        prop="goods_name"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.goods_name }}{{ scope.row.specs }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="150" prop="">
        <template #default="{ row }">
          <el-button type="text" @click="handleroperating(row)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>
    <add-banner ref="banner" @getlist="getlist"></add-banner>
    <el-row :gutter="20" style="padding: 20px 0px">
      <el-col :span="6">
        <span style="font-size: 30px">推荐商品板块设置</span>
      </el-col>
    </el-row>

    <el-divider></el-divider>
    <el-table stripe :data="classdata">
      <el-table-column
        label="序号"
        align="center"
        type="index"
      ></el-table-column>
      <el-table-column
        label="板块"
        align="center"
        prop="name"
      ></el-table-column>
      <!--      <el-table-column label="开关" align="center">-->
      <!--        <template slot-scope="scope">-->
      <!--          <el-switch-->
      <!--            v-model="scope.row.status"-->
      <!--            :active-value="'1'"-->
      <!--            :inactive-value="'0'"-->
      <!--            @change="handlerSwitch(scope.row)"-->
      <!--          ></el-switch>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column label="关联商品" align="center">
        <template slot-scope="scope">
          <span
            style="text-decoration: underline; cursor: pointer"
            @click="handlernum(scope.row)"
          >
            {{ scope.row.goods_num }}
          </span>
        </template>
      </el-table-column>
    </el-table>
    <add-goods ref="goods" @getlist="getlist"></add-goods>
  </div>
</template>

<script>
  import AddGoods from './components/AddGoods.vue'
  import AddBanner from './components/AddBanner.vue'
  import { postAction } from '@/api/Employee'
  export default {
    components: {
      AddBanner,
      AddGoods,
    },
    data() {
      return {
        tableData: [],
        url: {
          list: '/shopAdmin/config/banner-list',
          classlist: '/shopAdmin/config/cate-list',
          seitch: '/shopAdmin/config/cate-change',
        },
        srcList: [],
        classdata: [],
      }
    },
    mounted() {
      this.handlerlist()
      this.handlercalss()
    },
    methods: {
      getlist() {
        this.handlerlist()
        this.handlercalss()
      },
      handlernum(row) {
        this.$refs.goods.goodsnum = true
        this.$refs.goods.handlerData(row)
      },
      handlerSwitch(row) {
        postAction(this.url.seitch, { cate_id: row.id })
          .then((res) => {
            console.log(res)
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.handlercalss()
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlercalss() {
        postAction(this.url.classlist, {})
          .then((res) => {
            console.log(res)
            this.classdata = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerAdd() {
        this.$refs.banner.banner = true
      },
      handleroperating(row) {
        this.$refs.banner.banner = true
        this.$refs.banner.handlerdata(row)
      },
      handlerlist() {
        postAction(this.url.list, {})
          .then((res) => {
            this.srcList = new Array()
            console.log(res)
            this.tableData = res.data
            this.tableData.forEach((item) => {
              this.srcList.push(item.img_url)
            })
          })
          .catch((err) => {
            console.log(err)
          })
      },
    },
  }
</script>

<style></style>
